import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Logo from "./logo"

const Header = () => (
  <header>


      <div class="section header">

        <div class="container">

        <div class="row navigation">

            <div class="four columns">
              <Link to="/">
                <Logo/>
              </Link>
            </div>
        </div>

      </div>
    </div>

  </header>
)


export default Header
